import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SnackBarService } from "@services/snack-bar.service";

export enum CustomMessagesEndpointsEnum {
   ['/sports-events'] = "This external sport event is used in another tenant",
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private snackBarService: SnackBarService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = this.getErrorMessage(error);
        this.showErrorMessage(errorMessage, error);
        return throwError(error);
      })
    );
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    if (error.status === 500) {
      return 'Server error';
    } else if (error.error?.path?.endsWith('/sports-events') && error.status === 409) {
      return CustomMessagesEndpointsEnum['/sports-events'];
    } else if (CustomMessagesEndpointsEnum[error.error?.path]) {
      return CustomMessagesEndpointsEnum[error.error?.path];
    } else {
      return this.extractErrorMessage(error.error);
    }
  }

  private extractErrorMessage(error: any): string {
    let errorMessage = 'An error occurred. Please try again later.';
    if (error) {
      if (typeof error === 'string') {
        errorMessage = error;
      } else if (error.message) {
        if (typeof error.message === 'string') {
          errorMessage = error.message;
        } else if (Array.isArray(error.message)) {
          errorMessage = error.message.join(', ');
        }
      }
    }
    return errorMessage;
  }

  private showErrorMessage(errorMessage: string, error: HttpErrorResponse): void {
    if (errorMessage) {
      this.snackBarService.showSnackBar(errorMessage, true);
    } else {
      console.error('Unknown error occurred:', error);
    }
  }}
