

import { HTTP_INTERCEPTORS, HttpClient, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { enableProdMode, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  Router,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig
} from '@angular/router';
import * as Sentry from "@sentry/angular";

import { AppComponent } from '@app/app.component';
import { UriConfig } from '@app/app.config';
import { routes } from "@app/app.routing";
import { authInterceptorProviders } from '@app/core/interseptors/auth.interceptor';
import { ErrorHandlerInterceptor } from '@app/core/interseptors/error-handler.interceptor';
import { JwtTokenInterceptor } from "@app/core/interseptors/jwt-token.interceptor";
import { initializeAppFactory } from '@app/initializeAppFactory';
import { AuthGuard } from '@guards/auth.guard';
import { AuthService } from '@services/auth.service';
import { LocalStorageService } from '@services/local-storage.service';
import { SaasSettingsService } from '@services/saas-settings.service';

import { environment } from './environments/environment';


Sentry.init({
  dsn: environment.sentryDsn,
  ignoreErrors: [
    "Incorrect email or password",
    /Http failure response for https:\/\/(api|api-staging)\.playhunch\.io\/rounds\/\d+\/streak-round: 400 OK/i,
    /Can not convert .* to a Fragment \(looks like multiple versions of prosemirror-model were loaded\)/i,
    'Cannot POST /auth/local/signin',
  ],
  environment: environment.name,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});
if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtTokenInterceptor,
      multi: true
    },
    authInterceptorProviders,
    UriConfig,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorHandlerInterceptor,
        multi: true
    },
    {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
            showDialog: false,
        }),
    },
    {
        provide: Sentry.TraceService,
        deps: [Router],
    },
    {
        provide: APP_INITIALIZER,
        useFactory: () => () => {
        },
        deps: [Sentry.TraceService],
        multi: true,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: initializeAppFactory,
        deps: [SaasSettingsService, HttpClient, AuthService, LocalStorageService],
        multi: true
    },
    provideRouter(routes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled', // or 'top'
        anchorScrolling: 'enabled',
      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload'
      }),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
  .catch(err => console.error(err));
